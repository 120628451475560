<template>
  <label v-bind:for="component_id" v-if="component_meta.component.properties.show_label == 'Y'">
    {{ component_meta.label }}：
  </label>
  <span class="tip" v-if="component_meta.component.properties.show_label == 'Y'">{{ component_meta.tip }}</span>

  <div class="custom-control custom-switch">

    <label class="mr-2">{{ offText }}</label>

    <input type="checkbox" class="custom-control-input" v-bind:name="component_meta.variable + '[]'"
      v-bind:id="component_id" @change="update_input_value" v-bind:checked="component_value">

    <label class="custom-control-label" v-bind:for="component_id"></label>

    <label class="ml-2">{{ onText }}</label>
  </div>
</template>

<script>

export default {
  props: ['field', 'value', 'message'],
  emits: ['input'],
  data() {

    let labels = this.field.component.properties.label.split(',');

    // 預設值 及 初始化
    if (labels.length != 2) {
      labels = ['開啟', '關閉'];
    }

    let localValue = this.value;
    if(localValue == ''){
      localValue = this.field.component.properties.default;
    }

    return {
      component_id: 'switch-' + Math.random().toString().substring(2),
      component_meta: this.field,
      component_value: (localValue == 'Y'),
      offText: labels[1],
      onText: labels[0]
    }
  },
  methods: {
    update_input_value: function ($event) {
      $event.target.value = $event.target.checked ? 'Y' : '';
      this.$emit('input', $event);
    }
  }
}
</script>

<style scoped>
.custom-switch .custom-control-label::before {
  background-color: #7a828a;
}

.custom-switch .custom-control-input:checked~.custom-control-label::before {
  background-color: #28a745;
}

/* 新增開啟與關閉標籤的樣式 */
.custom-switch .custom-control-label .switch-on {
  position: absolute;
  left: -35px;
  top: 3px;
  color: #fff;
}

.custom-switch .custom-control-label .switch-off {
  position: absolute;
  right: -35px;
  top: 3px;
  color: #fff;
}

.custom-switch .custom-control-label {
  margin-left: 2.5rem !important;
}

.custom-control {
  padding-left: 0px !important;
}

span.tip {
  color: green;
}
</style>